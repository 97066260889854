import {BaseField} from '../base-field';

export class TextField<T = any> extends BaseField<T> {
    public sufixoIcone?: string;
    public prefixoIcone?: string;
    public dica?: string;
    public mascara?: string;
    public removerCaracteresMascara?: boolean = false;
    public todasMaiusculas?: boolean;
    public inputTipo?: 'text' | 'color' | 'date' | 'datetime-local' | 'email' | 'month' |
        'number' | 'password' | 'search' | 'tel' | 'time' | 'url' | 'textarea' | 'week' = 'text';

    public tamanhoMinimo?: number = undefined;
    public tamanhoMaximo?: number = undefined;

    public step?: number = undefined;

    public rangeMinimo?: number = undefined;
    public rangeMaximo?: number = undefined;
    public readonly: boolean | ((value: T) => boolean) = false;
    public linhas?: number = 1;

    constructor(init: Partial<TextField<T>>) {
        super('Text', init);
        Object.assign(this, init);
        if (!init.css) {
            this.css = 'col-sm-12 col-md-6 col-lg-4';
        }
    }
}
