import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from './login.service';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { NotificationService } from './notification.service';

@Injectable({ providedIn: 'root' })
export class ErrorHttpInterceptor implements HttpInterceptor {

  constructor(
    private notification: NotificationService,
    private ngZone: NgZone,
    private router: Router,
    private loginService: LoginService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next
      .handle(req)
      .pipe(
        catchError(erro => {
          if (erro instanceof HttpErrorResponse) {
            this.ngZone.run(() => {
              if (erro.status === 401) {
                this.loginService.logout();
              }
              else if(erro.error){
                if (erro.error.errors instanceof Array) {
                  this.notification.openErrorMultiLine(erro.error.errors);
                }
                else if (erro.error instanceof ProgressEvent) {
                  this.notification.openError('Não foi possível completar a ação, por favor tente novamente mais tarde.');
                }
                else if (typeof (erro.error) === 'string') {
                  const erroStr = erro.error.length > 200 ? erro.error.substring(0, 200) : erro.error
                  this.notification.openError(erroStr);
                }
              }
              else{
                this.notification.openError('Não foi possível completar a ação, por favor tente novamente mais tarde.');
              }
            });
          }
          throw erro;
        })
      );
  }
}
