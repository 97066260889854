
export class ApiResult<T = any> {
  success: boolean = false;
  data?: T;
  errors: Array<string> = [];
}
export class ListaPaginada<T = any> {
  total: number = 0;
  itens: Array<T> = [];
  pagina: number = 1;
  titulo?: string;
}
