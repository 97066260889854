import { API_URL, ApiResult, CrudService } from 'ui-shared';
import { Inject, Injectable } from "@angular/core";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { MaterialEntity } from "@treinamento/models";
import { Observable } from "rxjs";

@Injectable({ providedIn: 'root' })
export class MaterialDataService extends CrudService<MaterialEntity>
{
    protected url: string;

    constructor(
        @Inject(API_URL) urlBase: string,
        @Inject(HttpClient) protected httpClientRef: HttpClient
    )
    {
        super(urlBase, httpClientRef);
        this.url = `${urlBase}/v1/Material`;
    }

    public downloadById = (id: string): Observable<HttpResponse<Blob>> => {
        return this.httpClient.get(`${this.apiUrlWithId(id)}/download`, {observe: 'response', responseType: 'blob'});
    }
}
